import { ThemeProvider } from '@material-ui/core';
import LogoutIcon from '@mui/icons-material/Logout';
import { Card, CardContent, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import { blueGrey, grey } from '@mui/material/colors';
import { useContext } from 'react';
import { Helmet } from 'react-helmet-async';
import { Redirect, Route, Switch } from 'react-router-dom';
import appRoutes from 'routes/appRoutes';
import theme from 'theme';
import { AuthContext } from '../../context/AuthContext';
const logo = require('theme/assets/logo.png');

const routes = (
    <Switch>
      {appRoutes.map((prop, key) => {
        if (prop.redirect) {
          return <Redirect from={prop.path} to={prop.to} key={key} />;
        }
        return <Route {...prop} key={key} />;
      })}
    </Switch>
  );

const HeaderContainer = (props) => {
  const { logout, user } = useContext(AuthContext);

    return (
        <ThemeProvider theme={theme}>
            <div>
                <Helmet>
                    <title>ViewsML</title>
                </Helmet>
                <div className="container">
                    <Grid container >
                        <Card sx={{width: '100%', height: 100, zIndex: 3, marginTop: -1, bgcolor: blueGrey[400], color: 'white'}}>
                            <Grid container justifyContent={"center"}>
                                <Grid item container xs={4} sx={{ textAlign: "left", paddingLeft: 1, alignContent: 'center', paddingTop: 2 }}>
                                    <Grid item xs={2}>
                                        <img src={logo.default} alt="Icon" style={{width: 250, height: "auto"}}/>
                                    </Grid>
                                </Grid>
                                <Grid item xs={4}>
                                    <CardContent />
                                </Grid>
                                <Grid item xs={4} >
                                    <CardContent sx={{paddingRight: 1, paddingTop: 6}}>
                                        <Grid container justifyContent={"right"} columns={2} spacing={2}>
                                            <Tooltip title="Logout">
                                                <IconButton sx={{color: grey[200]}} onClick={logout}>
                                                    <LogoutIcon />
                                                </IconButton>
                                            </Tooltip>
                                            <Typography justifyContent="center" align='center' textAlign="center" sx={{textAlign: 'center', textJustify: 'center', paddingTop: 1}} >
                                                {(user && user.display_name) ? user.display_name : "Logged in"}
                                            </Typography>
                                        </Grid>
                                    </CardContent>
                                </Grid>
                            </Grid>
                        </Card>
                    </Grid>
                    <div className="children">
                        {routes}
                    </div>
                </div>
            </div>
        </ThemeProvider>
    )
};
export default HeaderContainer;