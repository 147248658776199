import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

const ConfigContext = React.createContext({});
const CONFIG_URL = window.__RUNTIME_CONFIG__?.API_HOST ? 'https://' + window.__RUNTIME_CONFIG__?.API_HOST : '';

const ConfigProvider = ({ children }) => {
  const [config, setConfig] = useState();
  useEffect(() => {
    console.log('running')
    const fetchConfig = async () => {
      const resp = await fetch('/api/1.0.0/.well-known/config.json');
      console.log(resp)
      if (resp.ok) {
        const json = await resp.json();
        setConfig(json);
      }
    };

    if (!config) {
      fetchConfig();
    }
  }, [config]);

  return (
    <ConfigContext.Provider
      value={
        config
          ? {
              basePath: '',
              ...config.aud,
              publicURL: config.public_url,
            }
          : {}
      }
    >
      {children}
    </ConfigContext.Provider>
  );
};
ConfigProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { ConfigProvider, ConfigContext };

