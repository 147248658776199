import OpenSeaDragon from "openseadragon";
import { useEffect, useState } from 'react';

import DownIcon from '@mui/icons-material/KeyboardArrowDown';
import UpIcon from '@mui/icons-material/KeyboardArrowUp';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { green } from '@mui/material/colors';
import Fab from '@mui/material/Fab';

import { CheckBoxOutlineBlankOutlined, CheckBoxOutlined } from "@mui/icons-material";
import { ToggleButton, Tooltip, Typography } from '@mui/material';
import image from 'theme/assets/icon.png';
import { useAlgorithms, useImageEngine, useWindowDimensions } from "../../../hooks";

const ImageViewer = (props) => {
    const [viewer, setViewer] = useState();
    const [annotationOpen, setAnnotationOpen] = useState(false);
    const [displayAlt, setDisplayAlt] = useState(false);
    const [lastUri, setLastUri] = useState("");
    const [theRand, setTheRand] = useState(Math.floor(Math.random() * 100))
    const [theMeta, setTheMeta] = useState();
    const { width, height } = useWindowDimensions();
    const { requestImagesFromServerAsync, activeImage } = useImageEngine();
    const { algoNameForId } = useAlgorithms();

    const fabStyle = {
        position: 'absolute',
        bottom: annotationOpen ? 125 : 50,
        right: 16,
        color: 'common.white',
        bgcolor: green[500],
        '&:hover': {
            bgcolor: green[600],
        },
    };

    const viewStyle = {
        width: width * 0.579,
        height: height * 0.9,
        backgroundImage: `url(${image})`,
    }

    const getImages = async () => {
        await requestImagesFromServerAsync();
    };

    useEffect(() => {
        getImages();
        return () => {
            viewer && viewer.destroy();
        };
    }, []);


    useEffect(() => {
        if (activeImage && activeImage.uri) {
            if (activeImage.uri != lastUri) {
                setLastUri(activeImage.uri)
                setDisplayAlt(false)
                setTheRand(Math.floor(Math.random() * 100))
                setTheMeta(activeImage.meta)
            }
            viewer && viewer.destroy();

            const isDzi = /\.dzi$/.test(activeImage.uri);
            const tileSources = isDzi ? (displayAlt ? activeImage.thumbnail : activeImage.uri) : {
                type: 'image',
                url: displayAlt ? activeImage.thumbnail : activeImage.uri,
                buildPyramid: true,
            }
            setViewer(
                OpenSeaDragon({
                    id: "openSeaDragon",
                    prefixUrl: "openseadragon-images/",
                    animationTime: 0.5,
                    blendTime: 0.1,
                    constrainDuringPan: true,
                    maxZoomPixelRatio: 2,
                    minZoomLevel: 1,
                    visibilityRatio: 1,
                    zoomPerScroll: 2,
                    showNavigator: true,
                    timeout: 600000,
                    tileSources: tileSources
                })
            );
        }
    }, [activeImage, displayAlt]);

    const InitOpenseadragon = () => {
        viewer && viewer.destroy();
    };

    return (
        <div>
            {activeImage && activeImage.processed && (
                <ToggleButton
                    color="secondary"
                    value="displayProcessed"
                    selected={displayAlt}
                    sx={{ position: 'absolute', zIndex: 5, marginTop: 5, textTransform: 'none' }}
                    onChange={() => setDisplayAlt(!displayAlt)}>
                    {!displayAlt ? (<CheckBoxOutlineBlankOutlined />) : (<CheckBoxOutlined />)} <Typography>Display Prediction</Typography>
                </ToggleButton>)
            }
            {annotationOpen ? (
                <Card variant="outlined" sx={{ height: 150, width: width * 0.579, position: 'absolute', bottom: 0, zIndex: 5 }}>
                    <CardContent>
                        <Typography variant='h5'>
                            {activeImage.description}
                        </Typography>
                        <Typography variant='body2'>
                            Algorithm: {algoNameForId(activeImage.algo_id)}
                        </Typography>
                        {theMeta && activeImage.processed && (
                            <div>
                                <Typography variant='body2'>
                                    Predicted Pos: {activeImage.meta.pos}
                                </Typography>
                                <Typography variant='body2'>
                                    Predicted Neg: {activeImage.meta.neg}
                                </Typography>
                                <Typography variant='body2'>
                                    Predicted Index: {activeImage.meta.index}
                                </Typography>
                            </div>)}
                    </CardContent>
                </Card>
            ) : (
                activeImage && (<Card variant="outlined" sx={{ width: width * 0.579, position: 'absolute', bottom: 0, zIndex: 5 }}>
                    <CardContent>
                        <Typography variant='h5'>
                            {activeImage.description}
                        </Typography>
                    </CardContent>
                </Card>)
            )}
            <div
                id="openSeaDragon"
                style={viewStyle}
            >
            </div>
            {activeImage && (<Fab sx={fabStyle} aria-label={'annotations'} onClick={() => setAnnotationOpen(!annotationOpen)}>
                <Tooltip title={annotationOpen ? "Close Details" : "Open Details"} placement="left">
                    {
                        annotationOpen ? (
                            <DownIcon />
                        ) : (
                            <UpIcon />
                        )
                    }
                </Tooltip>
            </Fab>)}
        </div>
    );
}

export default ImageViewer;