import { Auth, HeaderContainer } from '../containers';
console.log(Auth)
const indexRoutes = [
  { path: '/login', container: Auth, requireAuth: false },
  { path: '/register', container: Auth, requireAuth: false },
  { path: '/', container: HeaderContainer, requireAuth: true },
];

export { indexRoutes }; // eslint-disable-line

