import { createAction } from 'redux-actions';

import RestAPI from '../api/RestAPI';
const GET_IMAGE_DETAIL = 'engine/GET_IMAGE_DETAIL';
const RECEIVE_IMAGES = 'engine/RECEIVE_IMAGES';
const SEND_IMAGE = 'engine/SEND_IMAGE';
const REQUEST_IMAGE = 'engine/REQUEST_IMAGE';
const SET_ACTIVE_IMAGE = 'engine/SET_ACTIVE_IMAGE';
const UPDATE_IMAGE = 'engine/UPDATE_IMAGE'
const SET_LOADING = 'engine/SET_LOADING';

const initialState = {
    images: [],
    error: [],
    loading: false,
    processedImages: [],
    requestedImages: [],
    imageToUpload: [],
    activeImage: undefined
}

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_LOADING:
            return Object.assign({}, state, {loading: action.payload})
        case UPDATE_IMAGE:
        case RECEIVE_IMAGES: {
            return Object.assign({}, state, {images: action.payload})
        }
        case SET_ACTIVE_IMAGE: {
            return Object.assign({}, state, {activeImage: action.payload})
        }
        default:
            return state;
    }
}

const getImageWithAnnotation = async () => {
    return 'foo'
}

const ImageEngine = (function () {
    const getAllImages   = (state) => state.engine.images;
    const getProcessed   = (state) => state.engine.processedImages;
    const getRequested   = (state) => state.engine.requestedImages;
    const getLoading     = (state) => state.engine.loading;
    const getError       = (state) => state.engine.error;
    const getActiveImage = (state) => state.engine.activeImage;

    const fetchImages    = createAction(RECEIVE_IMAGES, RestAPI.imageList)
    const uploadImage    = createAction(SEND_IMAGE, RestAPI.imageCreate)
    const getImageDetail = createAction(GET_IMAGE_DETAIL, getImageWithAnnotation)
    const modifyImage    = createAction(UPDATE_IMAGE, RestAPI.imageUpdate);
    const startRequest   = () => ({ type: REQUEST_IMAGE })
    const setActiveImage = (image) => ({ type: SET_ACTIVE_IMAGE, payload: image})
    const setLoading     = (isLoading) => ({ type: SET_LOADING, payload: isLoading})

    return {
        fetchImages   ,
        getAllImages  ,
        getProcessed  ,
        getRequested  ,
        getLoading    ,
        getError      ,
        uploadImage   ,
        getImageDetail,
        startRequest  ,
        getActiveImage,
        setActiveImage,
        modifyImage   ,
        setLoading    ,
    }
})();

export default ImageEngine;