import { createTheme } from '@material-ui/core';

import overrides from './overrides';
import palette from './palette';
import typography from './typography';

const theme = createTheme({
  palette,
  typography,
  overrides,
  props: {
    MuiTextField: {
      variant: 'outlined',
    },
    MuiButtonBase: {
      // disableRipple: true
    },
  },
});

export default theme;
