import PropTypes from 'prop-types';
import React from 'react';

import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


export default function SimpleMenu({ menuItems, selectedItem, selectItem, size, placeholder }) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelect = (item) => {
    handleClose();
    selectItem(item);
  };

  let padding = 0;
  let component = 'h3';
  let variant = 'overline';

  if (size === 'normal') {
    padding = 8;
    component = 'div';
    variant = undefined;
  }

  return (
    <div>
      <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick} style={{ padding }}>
        <Typography component={component} style={{ textAlign: 'left' }} variant={variant}>
          <div>{selectedItem ? selectedItem : placeholder}</div>
        </Typography>
        <ExpandMoreIcon fontSize="small" />
      </Button>
      <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        <div>
          {menuItems.map((c) => (
            <MenuItem key={c} onClick={() => handleSelect(c)}>
              {c}
            </MenuItem>
          ))}
        </div>
      </Menu>
    </div>
  );
}

SimpleMenu.propTypes = {
  menuItems: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectedItem: PropTypes.string.isRequired,
  selectItem: PropTypes.func.isRequired,
  size: PropTypes.string,
  placeholder: PropTypes.string,
};

SimpleMenu.defaultProps = {
  size: 'small',
};
