import _ from 'lodash';
import Swagger from 'swagger-client';

const RestAPI = (function () {
    let refreshSession;
    let token;
    let swagger;
    
    const getClientOptions = () => {
        return {
            requestInterceptor: (req) => {
            if (_.startsWith(req.url, 'https://api.idunn.io')) {
                req.url = _.replace(req.url, 'https://api.idunn.io', '/api');
                req.headers.Authorization = `Bearer ${token.access_token}`;
            }
            return req;
            },
        };
    };
    
    const initialize = (_token, _refreshSession) => {
            refreshSession = _refreshSession;
            token = _token;
            swagger = new Swagger('/api/1.0.0/swagger.json', getClientOptions());
            console.log(swagger)
    };
    
    const getSwaggerClient = async () => {
        try {
            await refreshSession(token);
            return swagger;
        } catch (err) {
            console.log('Error with swagger', err);
            throw err;
        }
    };

    const callAPI = async (apiCall, params) => {
        try {
            const client = await getSwaggerClient();
    
            const f = _.get(client, apiCall);
            const response = await f(params);
            if (response.status >= 200 && response.status < 300) {
                return response.body;
            }
            return response;
        } catch (err) {
            console.log('Error with API call', apiCall, params, err);
            throw err;
        }
    };

    const algoList = async () => {
        return callAPI('apis.algos.algoList');
    };

    const imageCreate = async (imageInfo) => {
        return callAPI('apis.images.imageCreate', 
        {
            image: 
            {
                algo_id: imageInfo.algo_id,
                organization_id: imageInfo.organization_id,
                description: imageInfo.description,
                uri: imageInfo.unstainedUri,
                tags: imageInfo.tags,
                thumbnail: imageInfo.stainedUri
            }
        });
    };

    const imageUpdate = async (imageInfo) => {
        return callAPI('apis.images.imageUpdate', {image_id: imageInfo.id, imageInfo});
    }

    const imageList = async (request) => {
        if (!!request){
            return callAPI('apis.images.imageList', {algo_id: request.algo_id, organization_id: request.organization_id});
        }
        return callAPI('apis.images.imageList')
    };

    const organizationList = () => {
        return callAPI('apis.organizations.organizationList');
    };

    return {
        initialize,
        algoList,
        imageCreate,
        imageUpdate,
        imageList,
        organizationList,
    }
})();

export default RestAPI;