import { Box, Grid } from '@mui/material';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import MuiAlert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import Typography from '@mui/material/Typography';

import useImageEngine from '../../hooks/useImageEngine';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { ImageViewer, UploadForm } from './components';
import ImageInfoTile from './components/ImageInfoTile';

const Dashboard = () => {
    const colSpacings = { xs: 1, sm: 2, md: 3 };
    const [imageViewSections, setImageViewSections] = useState();
    const [imageViewSectionsProcessing, setImageViewSectionsProcessing] = useState();
    const [sideView, setSideView] = useState('imgView');
    const { listImages, requestImagesFromServerAsync } = useImageEngine();
    const { height, width } = useWindowDimensions();
    const [openSuccess, setOpenSuccess] = useState(false);
    
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpenSuccess(false);
      };

      const Alert = React.forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
      });

    useEffect(() => {
        const newImageSections = [];
        const newProcessingImageSections = [];
        _.forEach(listImages, (image, index) => {
            if (image.processed) {
                newImageSections.push(
                    <Grid item key={image.id}>
                        <Typography variant="subtitle2" sx={{paddingTop: 3
                        }}>
                            {image.description}
                        </Typography>
                        <Grid container rowSpacing={2} columnSpacing={colSpacings}>
                            <ImageInfoTile image={image}/>
                        </Grid>
                    </Grid>
                );
            } else {
                newProcessingImageSections.push(
                    <Grid item key={image.id}>
                        <Typography variant="subtitle2" sx={{paddingTop: 3, textAlign: 'center'
                        }}>
                            {image.description}
                        </Typography>
                        <Grid container rowSpacing={2} columnSpacing={colSpacings}>
                            <ImageInfoTile image={image}/>
                        </Grid>
                    </Grid>
                );
            }
        })
        setImageViewSections(newImageSections)
        setImageViewSectionsProcessing(newProcessingImageSections)
    }, [listImages]);
    
    return (
        <Box sx={{flexGrow: 1}}>
            <Grid container spacing={2} >
                <Grid item xs={5}>
                    <Accordion>
                        <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                        >
                            <Typography sx={{ width: '33%', flexShrink: 0 }}>
                                Upload Slides
                            </Typography>
                            <Typography variant='subtitle2' sx={{ color: 'text.secondary' }}>Upload images for processing</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container rowSpacing={2} columnSpacing={colSpacings}>
                                <UploadForm openSnack={openSuccess} />
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion onChange={(event, expanded) => {
                        if (imageViewSectionsProcessing && imageViewSectionsProcessing.length > 0) 
                        {
                            requestImagesFromServerAsync();
                            const terminator = setInterval(() => {
                                requestImagesFromServerAsync();
                            }, 30000) // fire every minute for five minutes

                            setTimeout(() => clearInterval(terminator), 300000);
                        }
                    }}>
                        <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                        >
                            <Typography sx={{ width: '33%', flexShrink: 0 }}>
                                Processing
                            </Typography>
                            <Typography variant='subtitle2' sx={{ color: 'text.secondary' }}>View slides currently processing</Typography>
                        </AccordionSummary>
                        <AccordionDetails sx={{maxHeight: height * 0.58, overflow: 'auto'}}>
                            <Grid container rowSpacing={2} columnSpacing={colSpacings}>
                                {
                                    imageViewSectionsProcessing && 
                                    imageViewSectionsProcessing.length > 0 ? (
                                        imageViewSectionsProcessing
                                    ) : (
                                        <Typography sx={{ color: 'text.secondary', paddingLeft: 5, paddingTop: 3}} variant="subtitle2">
                                            No Slides currently processing! Perhaps you'd like to upload one?
                                        </Typography>
                                    )}
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion onChange={(event, expanded) => {
                        if (imageViewSectionsProcessing && imageViewSectionsProcessing.length > 0) 
                        {
                            requestImagesFromServerAsync();
                            const terminator = setInterval(() => {
                                requestImagesFromServerAsync();
                            }, 30000) // fire every minute for five minutes

                            setTimeout(() => clearInterval(terminator), 300000);
                        }
                    }}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography sx={{ width: '33%', flexShrink: 0 }}>
                                Slide Gallery
                            </Typography>
                            <Typography variant='subtitle2' sx={{ color: 'text.secondary' }}>View Processed Slides</Typography>
                        </AccordionSummary>
                        <AccordionDetails sx={{maxHeight: height * 0.58, overflow: 'auto'}}>
                        <Grid container columnSpacing={colSpacings}>
                            {
                                imageViewSections && 
                                imageViewSections.length > 0 ? (
                                    imageViewSections
                                ) : (
                                    <Typography sx={{ color: 'text.secondary', paddingLeft: 5}} variant="subtitle2">
                                        No Processed Slides available for viewing! Perhaps you'd like to upload one, or check the status above?
                                    </Typography>
                                )}
                        </Grid>
                        </AccordionDetails>
                    </Accordion>
                    <Snackbar open={openSuccess} autoHideDuration={6000} onClose={handleClose}>
                        <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                            Upload Complete!
                        </Alert>
                    </Snackbar>
                </Grid>
                <Grid item justifyContent="center" xs={7} sx={{ minHeight: '100%' }}>
                    <Box
                        sx={{
                            width: '100%',
                            height: height * 0.9,
                            backgroundColor: 'primary.dark',
                        }}
                    >
                        {sideView === 'imgView' && (<ImageViewer />)}
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}

export default Dashboard;