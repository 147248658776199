import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';

const AuthRoute = ({ container: Component, ...props }) => {
  const [routeProtected] = useState(props.requireAuth);
  const [granted, setGranted] = useState(undefined);
  const { sessionValid, sessionLoading } = useContext(AuthContext);

  // useEffect(() => {
  // If we are still establishing the tokens, lets not set the granted flag just yet
  if (!sessionLoading && granted === undefined && routeProtected === true) {
    if (sessionValid) {
      setGranted(true);
    } else {
      setGranted(false);
    }
  }
  // }, [granted, sessionValid, routeProtected, sessionLoading]);

  if (routeProtected === false || granted) {
    return <Component {...props} />;
  }
  if (granted === undefined) {
    return <div />;
  }
  return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />;
};

AuthRoute.propTypes = {
  container: PropTypes.oneOfType([PropTypes.func, PropTypes.node, PropTypes.object]).isRequired,
  requireAuth: PropTypes.bool,
  location: PropTypes.object,
};

AuthRoute.defaultProps = {
  location: undefined,
  requireAuth: true,
};

export default AuthRoute;
