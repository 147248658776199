import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import promiseMiddleware from 'redux-promise';
import * as Algorithm from './Algorithm';
import * as ImageEngine from './ImageEngine';
import * as Organizations from './Organizations';
// stateful things will live here
const appReducer = combineReducers({
    engine:ImageEngine.reducer,
    organizations: Organizations.reducer,
    algo: Algorithm.reducer,
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose; // eslint-disable-line

const loadState = () => {
  const serializedState = localStorage.getItem('state'); // eslint-disable-line
  try {
    if (serializedState === null) {
      return {};
    }
    return {}; // JSON.parse(serializedState);
  } catch (err) {
    return {};
  }
};

const saveState = (state) => {
  console.log(state)
  try {
    const serializedState = JSON.stringify(
      state
    );
    // localStorage.setItem('state', serializedState); // eslint-disable-line
  } catch (err) {
    console.log('Could not save state', err); // eslint-disable-line
  }
};

const persistedState = loadState();

const rootReducer = (state, action) => {
    if (action.type === 'LOGOUT_USER' || action.type === 'REFRESH') {
      // This eventually should be stored to the USER account
      // activeSettings: state.activeSettings,
      return appReducer({}, action);
    }
    return appReducer(state, action);
  };

  const middleware = composeEnhancers(applyMiddleware(promiseMiddleware));

const store = createStore(rootReducer, persistedState, middleware);

store.subscribe(() => {
  saveState(store.getState());
});

export const getState = () => store.getState();

export default store;