import { useDispatch, useSelector } from 'react-redux';
import ImageEngine from 'redux/ImageEngine';
import Organizations from 'redux/Organizations';
const {InteractiveBrowserCredential} = require("@azure/identity");
const {BlobServiceClient} = require("@azure/storage-blob");

const signInOptions = {
    // the client id is the application id, from your earlier app registration
    clientId: "06d83bea-6d8f-482a-9cb7-a558bfba3b93",
    // this is your tenant id - the id of your azure ad tenant. available from your app registration overview
    tenantId: "890cd0fe-bcd3-4afe-82c4-45c0b90ad8fb"
}

let globalBlob = undefined;


const useFileUpload = () => {
    const dispatch = useDispatch();
    const activeOrganizationId = useSelector(Organizations.getActiveOrganizationId);
    const imageUploadStart = () => {
        return dispatch(ImageEngine.setLoading(true))
    }

    const imageUploadEnd = () => {
        return dispatch(ImageEngine.setLoading(false))
    }
    const getServiceBlob = () => {
        if (!!globalBlob) {
            return globalBlob
        }

        const blobServiceClient = new BlobServiceClient(
            // this is the blob endpoint of your storage acccount. Available from the portal 
            // they follow this format: <accountname>.blob.core.windows.net for Azure global
            // the endpoints may be slightly different from national clouds like US Gov or Azure China
            "https://viewsmlstorage.blob.core.windows.net/?sv=2021-06-08&ss=bfqt&srt=sco&sp=rwdlacupyx&se=2032-11-10T04:46:52Z&st=2022-11-09T20:46:52Z&spr=https,http&sig=qTyvW7cO4KfTAbF15G%2BhD9spo2cE4eKejsKpZZUIzSc%3D"
            // new InteractiveBrowserCredential(signInOptions)
        )

        globalBlob = blobServiceClient;

        return globalBlob;
    }
    const uploadFile = async (file, fileName) => {
        const blobServiceClient = getServiceBlob();

        let org = activeOrganizationId;

        console.log(org, file, fileName)
        
        const containerClient = blobServiceClient.getContainerClient('unstained-files');
        const name = fileName;
        const blockBlobClient = containerClient.getBlockBlobClient(name);
        
        // set mimetype as determined from browser with file upload control
        const options = { blobHTTPHeaders: { blobContentType: file.type } };
        
        imageUploadStart();
        await blockBlobClient.uploadBrowserData(file, options);
        imageUploadEnd();

        await blockBlobClient.setMetadata({orgId: org});
        return blockBlobClient.url.split('?')[0];
    }

    const containerExists = async (containerName) => {
        const blobServiceClient = getServiceBlob();

        const contIter = blobServiceClient.listContainers();
        let exists = false;
        let containerItem = await contIter.next();
        while (!containerItem.done && !exists) {
            if (containerItem.value.name === containerName) {
                exists = true;
            }
            containerItem = await contIter.next();
        }

        return exists;
    }

    const createContainer = async (containerName) => {
        const blobServiceClient = getServiceBlob();

        const containerClient = blobServiceClient.getContainerClient(containerName);
        const createContainerResp = await containerClient.create();
        return !createContainerResp.console.error();
    }

    return {
        uploadFile,
    }
}

export default useFileUpload;