import { CssBaseline } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Switch } from 'react-router-dom';

import { AuthProvider } from 'context/AuthContext';
import { ConfigProvider } from 'context/ConfigContext';
import { HelmetProvider } from 'react-helmet-async';
import store from 'redux/store';
import { indexRoutes } from 'routes';
import theme from 'theme';
import { ScrollReset } from './components';
import Route from './routes/AuthRoute';


function App() {
  return (
    <Router>
      <ScrollReset />
      <AuthProvider>
        <Switch>
          {indexRoutes.map((prop, key) => {
            return <Route {...prop} key={key} />;
          })}
        </Switch>
      </AuthProvider>
    </Router>
  );
}

ReactDOM.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <CssBaseline />
        <HelmetProvider>
          <ConfigProvider>
            <App />
          </ConfigProvider>
        </HelmetProvider>
    </ThemeProvider>
  </Provider>,
  document.getElementById('root'), // eslint-disable-line
);