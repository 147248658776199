import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { LinearProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import image from 'theme/assets/icon.png';
import authRoutes from '../../routes/authRoutes';

const transition = {
  transition: 'all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)',
};

const authStyle = makeStyles(() => ({
  wrapper: {
    top: '0',
    height: '100vh',
    display: 'flex',
  },
  mainPanel: {
    width: '100%',
    overflow: 'auto',
    position: 'relative',
    float: 'right',
    ...transition,
    maxHeight: '100%',
    overflowScrolling: 'touch',
    justifyContent: 'center',
    zIndex: 2,
  },
  content: {
    marginTop: '0px',
    padding: '30px 15px',
    minHeight: 'calc(100% - 123px)',
    position: 'absolute',
    top: '50%',
    left: '50%',
    zIndex: 10,
    transform: 'translate(-50%,-50%)',
  },
  container: {
    paddingRight: '15px',
    paddingLeft: '15px',
    marginRight: 'auto',
    marginLeft: 'auto',
  },
  background: {
    position: 'absolute',
    zIndex: '1',
    height: '100%',
    width: '100%',
    display: 'block',
    top: '0',
    left: '0',
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    '&:after': {
      position: 'absolute',
      zIndex: '3',
      width: '100%',
      height: '100%',
      content: '""',
      display: 'block',
      background: '#fff',
      opacity: '0.75',
    },
  },

  img: {
    width: '300px',
    top: '0px',
    position: 'absolute',
    verticalAlign: 'middle',
    border: '0',
  },
  error: {
    textAlign: 'center',
    paddingTop: '10px',
    maxWidth: 300,
  },
  anchor: {
    paddingTop: '20px',
  },
  logoImage: {
    display: 'inline-block',
  },
  logo: {
    position: 'relative',
    padding: '15px 15px',
    height: '120px',
    zIndex: '4',
    '&:after': {
      content: '""',
      position: 'absolute',
      bottom: '0',

      height: '1px',
      right: '15px',
      backgroundColor: 'rgba(180, 180, 180, 0.8)',
    },
  },
  button: {
    textAlign: 'right',
  },
  logoLink: {
    textTransform: 'uppercase',
    padding: '5px 0',
    display: 'block',
    fontSize: '18px',
    textAlign: 'left',
    fontWeight: '400',
    lineHeight: '30px',
    textDecoration: 'none',
    backgroundColor: 'transparent',
    '&,&:hover': {
      color: '#FFFFFF',
    },
  },
}));

const routes = (
  <Switch>
    {authRoutes.map((prop, key) => {
      if (prop.redirect) {
        return <Redirect from={prop.path} to={prop.to} key={key} />;
      }
      return <Route {...prop} key={key} />;
    })}
  </Switch>
);

const Auth = () => {
  const classes = authStyle();
console.log(routes)
  return (
    <div>
      <div className={classes.wrapper}>
        <div className={classes.mainPanel}>
          <React.Suspense fallback={<LinearProgress />}>
            <div className={classes.container}>{routes}</div>
          </React.Suspense>
        </div>
        <div className={classes.background} style={{ backgroundImage: `url(${image})` }}/>
      </div>
    </div>
  );
};

export default Auth;
