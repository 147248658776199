import _ from 'lodash';

const API_URL = window.__RUNTIME_CONFIG__?.API_HOST ? 'https://' + window.__RUNTIME_CONFIG__.API_HOST : '';
console.log(process.env.REACT_APP_API_TARGET_URL)
/**
 * @module api/OAuth
 *
 * This module performs all OAuth requests to the Atomic OAuth endpoint.
 */
const OAuth = (function () {
  let audience;
  let clientId;
  const apiPrefix = API_URL + '/oauth';

  const initialize = (_clientId, _audience) => {
    console.log('called init')
    clientId = _clientId;
    audience = _audience;
  };

  const checkResponse = (resp) => {
    if (resp.status >= 200 && resp.status < 300) {
      return resp;
    }
    throw resp;
  };

  const buildFormData = (params, encode = false) => {
    let formData = encode ? '' : `client_id=${clientId}`;
    _.forEach(params, (value, key) => {
      if (encode) {
        formData += `${encodeURIComponent(key)}=${encodeURIComponent(value)}&`;
      } else {
        formData += `&${key}=${value}`;
      }
    });
    return formData;
  };

  const oauthAuthorize = async (params) => {
    const p = { ...params, audience, response_type: 'code', code_challenge_method: 'S256' };
    const url = `${apiPrefix}/authorize?${buildFormData(p)}`;
    console.log(url)
    try {
      const resp = await fetch(url);
      checkResponse(resp);
      const token = new URL(resp.url).searchParams.get('request_token');
      const errDescription = new URL(resp.url).searchParams.get('error_description');
      const code = new URL(resp.url).searchParams.get('code');
      return { token, error: errDescription, authCode: code };
    } catch (error) {
      console.log('Authroize Failed', error);
      throw error;
    }
  };

  const login = async (params) => {
    console.log(apiPrefix)
    const url = `${apiPrefix}/login?client_id=${clientId}`;
    try {
      const resp = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
        },
        body: buildFormData(params, true),
      });
      console.log(resp)
      checkResponse(resp);
      const authCode = new URL(resp.url).searchParams.get('code');
      const errDescription = new URL(resp.url).searchParams.get('error_description');
      return { authCode, error: errDescription };
    } catch (error) {
      console.log('Error Logging In', error);
      throw error;
    }
  };

  const logout = async (redirectURI) => {
    const p = { redirect_uri: redirectURI };
    const url = `${apiPrefix}/logout?${buildFormData(p)}`;
    await fetch(url);
  };

  const oauthTokenGet = async (params) => {
    const p = { ...params, audience, client_id: clientId, grant_type: 'authorization_code' };
    const url = `${apiPrefix}/token`;
    try {
      const resp = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
        },
        body: buildFormData(p, true),
      });
      checkResponse(resp);
      const token = await resp.json();
      return { token };
    } catch (error) {
      console.log('Error getting token', error);
      throw error;
    }
  };

  const oauthTokenRefresh = async (params) => {
    const p = { ...params, audience, client_id: clientId, grant_type: 'refresh_token' };
    try {
      const url = `${apiPrefix}/token`;
      const resp = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
        },
        body: buildFormData(p, true),
      });
      checkResponse(resp);
      const token = await resp.json();
      return { token };
    } catch (error) {
      console.log('Error Refreshing Token ', error);
      throw error;
    }
  };

  const userInfo = async (token) => {
    const url = `${apiPrefix}/userInfo`;
    try {
      const resp = await fetch(url, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      checkResponse(resp);
      return await resp.json();
    } catch (err) {
      console.log('UserInfo Failed', err);
      throw err;
    }
  };

  const userPrincipal = async (token) => {
    const url = `${apiPrefix}/userPrincipal`;
    try {
      const resp = await fetch(url, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      checkResponse(resp);
      return await resp.json();
    } catch (err) {
      console.log('UserPrincipal Failed', err);
      throw err;
    }
  };

  const oauthPassword = async (params, token) => {
    const url = `${apiPrefix}/password`;
    const authorization = {};

    if (token) {
      authorization.Authorization = `Bearer ${token}`;
    }
    try {
      const resp = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
          ...authorization,
        },
        body: buildFormData(params, true),
      });
      checkResponse(resp);
      return resp;
    } catch (error) {
      console.log('Error getting token', error);
      throw error;
    }
  };

  return {
    oauthAuthorize,
    userInfo,
    userPrincipal,
    oauthTokenGet,
    oauthTokenRefresh,
    oauthPassword,
    login,
    logout,
    initialize,
  };
})();

export default OAuth;
