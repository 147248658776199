import Montserrat from '../assets/fonts/Montserrat/Montserrat-Medium.ttf';
import WorkSansRegular from '../assets/fonts/WorkSans/WorkSans-Regular.ttf';
import TradeGothic from '../assets/fonts/TradeGothic/trade-gothic.ttf';

const montserrat = {
  fontFamily: 'Montserrat',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `
    local('Montserrat'),
    local('Montserrat-Medium'),
    url(${Montserrat}) format('truetype')
  `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF', // eslint-disable-line 
};

const worksans = {
  fontFamily: 'Work Sans',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `
    local('Work Sans'),
    local('Work Sans Regular'),
    url(${WorkSansRegular}) format('truetype')
  `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF', // eslint-disable-line 
};

const tradegothic = {
  fontFamily: 'Trade Gothic',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `
    local('TradeGothic'),
    local('TradeGothic-Regular'),
    url(${TradeGothic}) format('truetype')
  `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF', // eslint-disable-line 
};
export default {
  '@global': {
    '@font-face': [montserrat, worksans, tradegothic],
  },
};
