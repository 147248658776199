import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';

import { AuthContext } from 'context/AuthContext';
import { useBasePath } from 'hooks';

import { Redirect } from 'react-router-dom';
import ForgotPassword from './ForgotPassword';
import LoginForm from './LoginForm';

const Login = ({ history }) => {
  const [loggingIn, setLoggingIn] = useState(false);
  const { login, sessionValid, authError: contextError } = useContext(AuthContext);
  const [authError, setAuthError] = useState();
  const [resetPage, setResetPage] = useState(undefined);
  const { p } = useBasePath();
  console.log('hello')

  /**
   * This effect will set the authentication error if the AuthContext has an error.
   *
   * @param {string} contextError error that resulted from the AuthContext - usually these are fatal or misconfigurations.
   */
  useEffect(() => {
    if (contextError) {
      setAuthError(contextError);
    }
  }, [contextError]);

  const onSubmit = async (form) => {
    setLoggingIn(true);
    const response = await login(form.username, form.password);
    console.log(response)
    try {
      if (response.authCode) {
        history.push(p('/'));
      } else {
        setLoggingIn(false);
        setAuthError(response.error);
      }
    } catch (err) {
      setLoggingIn(false);
      setAuthError(err);
    }
  };

  if (resetPage !== undefined) {
    return <ForgotPassword authError={authError} gotoLogin={() => setResetPage(undefined)} />;
  } else if (sessionValid && !loggingIn) {
    return <Redirect to="/" />;
  } else {
    return (
      <LoginForm onSubmit={onSubmit} authError={authError} loggingIn={loggingIn} gotoForgot={() => setResetPage(1)} />
    );
  }
};

Login.propTypes = {
  history: PropTypes.object,
};

Login.defaultProps = {
  history: undefined,
};

export default Login;
