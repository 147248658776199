import { useDispatch, useSelector } from "react-redux";

import ImageEngine from "../redux/ImageEngine";

const useImageEngine = () => {
    const dispatch = useDispatch();

    const isLoading          = useSelector(ImageEngine.getLoading);
    const getProcessedImages = useSelector(ImageEngine.getProcessed);
    const getRequestedImages = useSelector(ImageEngine.getRequested);
    const getImageErrors     = useSelector(ImageEngine.getError);
    const listImages         = useSelector(ImageEngine.getAllImages);
    const activeImage        = useSelector(ImageEngine.getActiveImage);

    const requestImagesFromServerAsync = () => {
        if (!isLoading) {
            dispatch(ImageEngine.startRequest());
            return dispatch(ImageEngine.fetchImages());
        }
    }

    const uploadImageToServerAsync = (image) => {
        console.log('uploading', image)
        return dispatch(ImageEngine.uploadImage(image)).then((resp) => {
            requestImagesFromServerAsync();
        });
    }

    const requestImageDetail = (image_id) => {
        return dispatch(ImageEngine.getImageDetail());
    }

    const setActiveImage = (image) => {
        return dispatch(ImageEngine.setActiveImage(image));
    }

    const imageUploadStart = () => {
        return dispatch(ImageEngine.setLoading(true))
    }

    const imageUploadEnd = () => {
        return dispatch(ImageEngine.setLoading(false))
    }

    return {
        isLoading,
        getProcessedImages,
        getRequestedImages,
        getImageErrors,
        activeImage,
        listImages,
        requestImageDetail,
        requestImagesFromServerAsync,
        uploadImageToServerAsync,
        setActiveImage,
        imageUploadStart,
        imageUploadEnd,
    }
};

export default useImageEngine;