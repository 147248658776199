// import moment from 'moment';
import _ from 'lodash';

import { createAction } from 'redux-actions';
import { createSelector } from 'reselect';

import RestAPI from '../api/RestAPI';

const SET_ACTIVE_ORGANIZATION = 'organizations/SET_ACTIVE_ORGANIZATION';
const REQUEST_ORGANIZATIONS = 'organizations/REQUEST_ORGANIZATIONS';
const RECEIVE_ORGANIZATIONS = 'organizations/RECEIVE_ORGANIZATIONS';

const initialState = {
  isFetchingOrganizations: false,
  activeOrganization: '',
  organizations: [],
  initialized: false,
  error: undefined,
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ACTIVE_ORGANIZATION: {
      if (action.organizationId === state.activeOrganization) return state;

      return Object.assign({}, 
                            state,
                            {
                              activeOrganization: action.organizationId ? action.organizationId : initialState.activeOrganization,
                            }
        );
    }

    case REQUEST_ORGANIZATIONS: {
      return Object.assign({}, state, {initialized: true, isFetchingOrginizations: true});
    }
    case RECEIVE_ORGANIZATIONS: {
      if (!action.payload) return state;

      let organizations;

      if (action.payload.length >= 1) {
        organizations = action.payload;
      }
      return Object.assign({}, state, organizations);
    }

    default:
      return state;
  }
};

const Organizations = (function () {
  const setActiveOrganization = (organizationId) => ({ type: SET_ACTIVE_ORGANIZATION, organizationId });
  const startFetch = () => ({ type: REQUEST_ORGANIZATIONS });
  const fetchOrganizations = createAction(RECEIVE_ORGANIZATIONS, RestAPI.organizationList); // idunnAPI.getProperties);

  const getIsFetchingOrganizations = (state) => state.organizations.isFetchingOrganizations;
  const getActiveOrganizationId = (state) => state.organizations.activeOrganization;
  const getOrganizations = (state) => state.organizations.organizations;

  const getActiveOrganization = createSelector([getOrganizations, getActiveOrganizationId], (organizations, id) => {
    console.log(organizations, id)
    const org = _.find(organizations, { id });
    return org;
  });

  return {
    setActiveOrganization,
    getIsFetchingOrganizations,
    startFetch,
    fetchOrganizations,
    getActiveOrganization,
    getOrganizations,
    getActiveOrganizationId,
  };
})();

export default Organizations;
