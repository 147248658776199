import {
  Dashboard
} from '../views';

const appRoutes = [
  { path: '/dashboard', component: Dashboard, exact: true, strict: true },
  { path: '/', component: Dashboard, exact: false, strict: false },
];

export default appRoutes;
