
import { Grid, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { grey } from '@mui/material/colors';
import TextField from '@mui/material/TextField';
import { SimpleMenu } from 'components';
import { useFileUpload } from 'hooks';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useAlgorithms, useImageEngine, useOrganizations } from '../../../hooks';

const UploadForm = ({openSnack}) => {
    const { uploadImageToServerAsync, isLoading } = useImageEngine();
    const { getAlgoList, requestAlgos } = useAlgorithms();
    const { activeOrganizationId } = useOrganizations();
    const [algoNames, setAlgoNames] = useState([]);
    const [selectedAlgo, setSelectedAlgo] = useState("");
    const [imageDesc, setImageDesc] = useState("");
    const [tags, setTags] = useState("");
    const [imageFile, setImageFile] = useState('');
    const [hasImportFile, setHasImportFile] = useState(false);
    const { uploadFile,  } = useFileUpload();
    
    if (!!openSnack) {
        openSnack(true);
    }

    const fileChangeHandler = (event) => {
        setImageFile(event.target.files[0]);
        setHasImportFile(true);
    };

    const fileSubmitHandler = async () => {
        if (hasImportFile) {
            const fileLocation = await uploadFile(imageFile, imageFile.name);
            handleUpload(fileLocation);
        } else {
            return false;
        }
        return false;
    };
    const handleFieldChange = (event, _field, item) => {
        setSelectedAlgo(item);
    };

    const getAlgoIdForName = (name) => {
        const id = _.find(getAlgoList, {'algo_name': name})
        return id.id
    }
/**
 *              algo_id: imageInfo.algo_id,
                organization_id: imageInfo.organization_id,
                description: imageInfo.description,
                uri: imageInfo.unstainedUri,
                tags: imageInfo.tags,
                thumbnail: imageInfo.stainedUri
 */
    const handleUpload = (endPointName) => {
        uploadImageToServerAsync(
            {
                algo_id: getAlgoIdForName(selectedAlgo),
                description: imageDesc,
                organization_id: activeOrganizationId,
                tags: tags ? [...tags.split(',')] : [],
                unstainedUri: endPointName.replace(".tiff", ".jpg"),
                stainedUri: endPointName.replace("unstained-files", "stain-files").replace(".tiff", "_stained.jpg")
            }).then(() => {
            }).catch((err) => {
                console.log(err)
            })
        setSelectedAlgo("");
        setImageDesc("");
        setTags("");
        setImageFile('')
    }
      
    useEffect(() => {
        requestAlgos();
    }, [])

    useEffect(() => {
        if(!!getAlgoList && getAlgoList.length > 0) {
            const algos = getAlgoList;
            let names = [];
            _.forEach(algos, (algo) => {
                names.push(algo.algo_name)
            })
            setAlgoNames(names)
        }
      }, [getAlgoList]);

    return (
        <Box
            sx={{
                '& .MuiTextField-root': { m: 1, width: '25ch' },
                paddingLeft: 5,
            }}
            noValidate
            autoComplete="off"
        >
            <Typography variant='subtitle2' sx={{color: grey[500]}}>
                Image Information
            </Typography>
            <div>
                <TextField
                    id="outlined-disabled"
                    disabled={isLoading}
                    label="Description"
                    value={imageDesc}
                    sx={{flex: 1}}
                    onChange={(event) => setImageDesc(event.target.value)}
                />
                <SimpleMenu 
                    placeholder="Algorithm Type Requested"
                    disabled={isLoading}
                    value={selectedAlgo}
                    menuItems={algoNames}
                    selectedItem={selectedAlgo}
                    selectItem={(item) => handleFieldChange({persist: true}, 'algo_id', item)}
                    size="normal"
                />
            </div>
            <Grid container sx={{flexDirection: 'column'}}>
                <TextField
                    id="outlined-multiline-static"
                    disabled={isLoading}
                    label="Tags (X, Y..)"
                    multiline
                    value={tags}
                    sx={{width: '300px'}}
                    rows={4}
                    onChange={(event) => setTags(event.target.value)}
                />
                <div sx={{marginTop: 2,}}>
                    <input disabled={isLoading} type="file" onChange={fileChangeHandler} />
                </div>
                <div sx={{flexDirection: "column"}}>
                    <Button sx={{marginTop: 2,}} disabled={isLoading} variant="outlined" onClick={() => {
                        fileSubmitHandler()
                    }}>Upload File</Button>
                    {isLoading && (<CircularProgress  sx={{marginTop: 2, marginBottom: -3, marginLeft: 2}}/>)}
                </div>
            </Grid>
        </Box>
    );
}

export default UploadForm;