import {
    Button, Grid
} from '@mui/material';
import PropTypes from 'prop-types';


import useImageEngine from "../../../hooks/useImageEngine";

const ImageInfoTile = ({ image }) => {
    // name, slide, description, thumbnail?
    const { thumbnail } = image;
    const uri = thumbnail;
    const { setActiveImage } = useImageEngine();

    const setActive = () => {
        setActiveImage(image);
    }

    return (
            <Grid item container xs={6} sm={4} md={3} sx={{flex: 1, flexDirection: 'column'}}>
                <Grid item xs={6} sm={4} md={3} alignItems="center">
                    <img src={uri} alt="Icon" style={{flex: 1, width: 150, height: 150, marginTop: 2}}/>
                    <Button variant="outlined" onClick={setActive} sx={{flex: 1, marginTop: 2}}>Display</Button>
                </Grid>
            </Grid>
    );
};

ImageInfoTile.propTypes = {
    image: PropTypes.object.isRequired
}

export default ImageInfoTile;