import { Card, CardActions, CardContent, CardHeader, Divider, withStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
const logo = require('theme/assets/logo.png');

const authCardStyle = (theme) => ({
  card: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    marginTop: theme.spacing(8),
    [theme.breakpoints.up(400 + theme.spacing(6))]: {
      width: 400,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  logo: {
    width: 280,
    marginTop: theme.spacing(3),
  },
  cardHeader: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  cardTitle: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  cardSubtitle: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: theme.spacing(3),
    marginBottom: -theme.spacing(5),
  },
  cardActions: {
    padding: 14,
    paddingTop: 0,
    display: 'block',
    height: 'auto',
    alignItems: 'center',
    jusitfyContent: 'center',
  },
  titleText: {
    ...theme.typography.h4,
    padding: theme.spacing(1),
  },
  titleDivider: {
    marginBottom: theme.spacing(1),
  },
});

const AuthCard = ({ classes, className, errorMessage, title, footer, children }) => {
  const { t } = useTranslation();

  return (
    <Card className={clsx(classes.card, className)}>
      <CardHeader
        classes={{
          root: classes.cardHeader,
          title: clsx(classes.cardTitle, className),
          subheader: classes.cardSubtitle,
        }}
        title={
          <>
            <img src={logo.default} className={classes.logo} alt="Logo" />
            {title && (
              <>
                <Typography color="textSecondary" className={classes.titleText} component="h4">
                  <div className={classes.titleDivider}>
                    <Divider />
                  </div>{' '}
                  {title}
                </Typography>
              </>
            )}
          </>
        }
        subheader={
          errorMessage && (
            <Typography className={classes.cardSubtitle} color="error">
              {t(errorMessage)}
            </Typography>
          )
        }
      />
      <CardContent>{children}</CardContent>
      {footer !== undefined ? <CardActions className={classes.cardActions}>{footer}</CardActions> : null}
    </Card>
  );
};

AuthCard.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  errorMessage: PropTypes.string,
  cardSubtitle: PropTypes.node,
  footer: PropTypes.node,
  title: PropTypes.string,
};

AuthCard.defaultProps = {
  className: undefined,
  errorMessage: undefined,
  cardSubtitle: undefined,
  footer: undefined,
  title: undefined,
};

export default withStyles(authCardStyle)(AuthCard);
